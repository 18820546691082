
import { Component, Vue } from 'vue-property-decorator'
import { SmokeDetail } from '../../types/smoke.d'
@Component({
  name: 'DetailList'
})
export default class DetailList extends Vue {
  private info = {
    deviceNumber: ''
  }

  private loading = false
  private dataList: Array<SmokeDetail> = []
  page = 1
  size = 10
  total = 0

  get projectId () {
    return this.$route.params.projectId as string
  }

  created () {
    this.getData()
  }

  // 获取列表数据
  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<SmokeDetail> }>(this.$apis.smoke.selectSmokeDetailPage, {
      projectId: this.projectId,
      page: this.page,
      size: this.size,
      deviceNumber: this.info.deviceNumber
    }).then(res => {
      this.total = res.total || 0
      this.dataList = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
